.about {
	width: 90%;
	margin-top: 10vh;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: space-between;
	font-weight: 500;
	@include screen("desktop") {
		// margin-top: 7vh;
	}
	@include screen("tablet") {
		// margin-top: 7vh;
		flex-direction: column;
	}
	@include screen("phone") {
		// margin-top: 7vh;
	}
	.left,
	.right {
		width: 45%;
		margin: auto;
		@include screen("tablet") {
			width: 90%;
		}
	}
	h1 {
		margin-top: 1vw;
	}
	p {
		text-align: justify;
		line-height: 150%;
		margin-top: 1vw;
	}
	ul {
		margin-top: 0.4vw;
		li {
			line-height: 150%;
		}
	}
	.bold {
		font-weight: bolder;
	}
}
